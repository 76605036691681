import React from "react";
import IndexPage from "./index"

const IndexPageES = () => (
  <IndexPage i18n="es"/>
);

export default IndexPageES;


